<template>
  <div>
    <v-row>
      <v-col sm="10">
        <Heading title="Счётчики" />
      </v-col>
      <v-col align="right" align-self="center">
        <v-btn color="blue" outlined @click="onAddClick">Добавить</v-btn>
      </v-col>
    </v-row>

    <v-skeleton-loader v-if="!isLoaded" :loading="!isLoaded" type="table-tbody" />

    <v-card v-for="meter in meters" :key="meter.id" tile>
      <v-card-text class="d-flex">
        <v-row>
          <v-col sm="3">
            {{ meter.id }}
          </v-col>

          <v-col sm="3">
            {{ meter.name }}
          </v-col>

          <v-col sm="3">
            {{ meter.type ? meter.type.name : '-' }}
          </v-col>
        </v-row>

        <div class="buttons">
          <v-btn text small @click="onEditClick(meter.id)">
            Редактировать
          </v-btn>

          <v-btn text small @click="onDeleteClick(meter.id)">
            Удалить
          </v-btn>
        </div>
      </v-card-text>
    </v-card>

    <DeleteConfirmation
      :visible="deleteConfirmationVisible"
      @confirm="deleteConfirm"
      @decline="deleteDecline"
    />

    <MeterModal
      :meter="modalItem"
      @close="onModalClose"
    />
  </div>
</template>

<script>
import { MetersService } from '@/common/api.service'
import DeleteConfirmation from '@/components/Modals/DeleteConfirmation'
import MeterModal from '@/views/Director/Meters/MeterModal'

export default {
  components: {
    DeleteConfirmation,
    MeterModal
  },

  data () {
    return {
      meters: [],
      isLoaded: false,
      toEditId: null,
      toDeleteId: null,
      deleteConfirmationVisible: false
    }
  },

  methods: {
    onAddClick () {
      this.toEditId = 0
    },

    onEditClick (id) {
      this.toEditId = id
    },

    onDeleteClick (id) {
      this.toDeleteId = id
      this.deleteConfirmationVisible = true
    },

    deleteConfirm () {
      this.deleteConfirmationVisible = false
      MetersService.delete(this.toDeleteId).then(() => this.fetchMeters())
    },

    deleteDecline () {
      this.deleteConfirmationVisible = false
    },

    onModalClose (needRefresh) {
      this.toEditId = null

      if (needRefresh) {
        this.meters = []
        this.isLoaded = false
        this.fetchMeters()
      }
    },

    fetchMeters () {
      MetersService
        .get()
        .then(({ data }) => {
          this.meters = data
          this.isLoaded = true
        })
    }
  },

  computed: {
    modalItem() {
      return this.toEditId !== 0 ? this.meters.find(meter => meter.id === this.toEditId) : {}
    }
  },

  beforeMount () {
    this.fetchMeters()
  }
}
</script>

<style scoped>
.buttons {
  display: flex;
  align-items: center;
}
</style>
